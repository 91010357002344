import { Box, Button, Typography, Snackbar, Alert, Modal } from '@mui/material';
import copytoClipboard from '../../Assets/copytoClipboard.svg';
import USDTIcon from '../../Assets/USDTIcon.svg';
import React, { useEffect, useState } from 'react';
import { Cell, Address } from "@ton/core";
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import defaultTx from '../../config/tx';
import axios from "../../api/axios.js"


const Checkout = ({userData}) => {
  const location = useLocation();
  const myTransaction = defaultTx;
  const walletAddress = location.state?.walletAddress || "Not connected"; // Get wallet address from location state
  const [tonConnectUI] = useTonConnectUI(); // Ensure tonConnectUI is initialized
  const wallet = useTonWallet(); // Get the connected wallet
  // const [tx, setTx] = useState(defaultTx); // Define transaction details
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false); // For the modal
  const [userDataStore, setUserData] = useState(null)
const [errorDAta, seterrorDAta] = useState(null)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
    alert("Wallet address copied to clipboard!");
  };
  // useEffect({
    
  // },[seterrorDAta])

  useEffect( ()=>{

    console.log("Error");
            
  },[seterrorDAta])

  const handleProceed = async () => {
    try {
      if (wallet && tonConnectUI) {
        
        // const userResponse = await axios.get(`/api/v1/users/getuserdata/${userData}`).then((res)=>{
        //   console.log("Data", res);       
        
        try {
          // Receiver address and amount to be transferred
          const receiverAddress = "0QDP1lQB8isB7oqm24yBsN1lf3Mz7AOGInnWR0KNYCKKGQ5G"; // Replace with actual receiver address
          const amount = 0.001; // Replace with the amount of USDT you want to transfer
          let hashHex ;
          // Build the transaction using myTransaction function
          // Send the transaction through tonConnectUI
          await tonConnectUI.sendTransaction(myTransaction).then((res) => {
            setShowSnackbar(true); // Show snackbar after transaction success
            
            console.log("Transaction successful:", res.boc);
            // Decode the BOC (Bag of Cells) and process the result
            const cell = Cell.fromBase64(res.boc)
            const bufferData = cell.hash();
            
            // Convert buffer to hexadecimal
            hashHex = Buffer.from(bufferData).toString("hex");
            console.log(`Transaction Hash: ${hashHex}`);
                
          }).catch((error) => {
            console.error("Error in Ton Wallet response:", error);
            // return
          });


          const userResponse = await axios.get(`/api/v1/users/getuserdata/${userData}`)
          // console.log("USer Data : ", data);
          
          
          // setUserData("User : ",userResponse.data._id)
          const wallet = Address.parse(walletAddress)
          console.log("Wallet Address : ",wallet.toString())
          // setUserData(userResponse.data._id)
          const storeUserWallet = await axios.post("/api/v1/users/wallet-store",
              {
                userId: userResponse.data._id,
                walletAddress: wallet.toString()
              }
          )
          // console.log(storeUserWallet.data);
    
          const getRunningGame = await axios.get("api/v1/game/getrunninggame",
            {
              userId: userResponse.data._id,
              walletAddress: wallet.toString()
            }
        )
    
        // console.log(getRunningGame.data._id);      
  
        const usdtTransferDB = await axios.post(`api/v1/game/user-session/${getRunningGame.data._id}`,
          {
                userTgId : Number(userData),
                wallet : wallet.toString(),
                gameSessionId : getRunningGame.data._id,
                paidAmount : Number(1),
                txnHash : hashHex,
                companyWallet : receiverAddress
          }
      )
  
  
          
          
        } catch (error) {
          console.error('Transaction failed:', error);
        }
      } else {
        alert('Wallet is not connected or tonConnectUI is unavailable.');
      }
    } catch (error) {
      console.error('Transaction failed:', error);
    }
  };
  

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    
    // Show the modal after 100 milliseconds
    setTimeout(() => {
      setShowModal(true); // Show the modal
      // Automatically close the modal after 3 seconds and call handleEnterGame
      setTimeout(() => {
        handleModalClose(); // Close the modal
      }, 3000);
    }, 100);
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    handleEnterGame(); // Call handleEnterGame when the modal closes
    localStorage.clear(); 
  };

  const handleEnterGame = () => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.close(); // Close Telegram app
    }
  };

  return (
    <Box sx={{ height: '100vh', overflow: 'hidden', position: 'relative' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ position: 'relative' }}
      >
        {/* Image and button container */}
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ marginTop: "36px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Typography sx={{
              fontFamily: "Urbanist",
              fontSize: "64px",
              fontWeight: 800,
              lineHeight: "64px",
              letterSpacing: "3px",
              textAlign: "center"
            }}>
              FACE
            </Typography>
            <Typography sx={{
              fontFamily: "Urbanist",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "3px",
              textAlign: "center",
              transform: "rotate(-6.5deg)",
              background: "white",
              borderRadius: "34px",
              marginTop: "-28px",
              padding: "6px 8px"
            }}>
              PAL
            </Typography>
          </Box>
          <Box sx={{ marginTop: "52px" }}>
            <Typography sx={{
              fontFamily: "Urbanist",
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "38.4px",
              textAlign: "center",
              color: "#010101"
            }}>
              Confirm your Details
            </Typography>
          </Box>

          <Box sx={{
            width: "345px",
            height: "103px",
            borderRadius: "24px",
            background: 'transparent',
            marginTop: "36px",
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
          }}>
            <Box sx={{ padding: "24px" }}>
              <Typography sx={{
                fontFamily: "Urbanist",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "17.92px",
                color: "#010101"
              }}>
                Connected Wallet Address
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: "12px",
                gap: "24px"
              }}>
                <Typography sx={{
                  fontFamily: "Urbanist",
                  fontSize: "22px",
                  fontWeight: 600,
                  lineHeight: "24.64px",
                  color: "#010101"
                }}>
                  {walletAddress ? `${walletAddress.slice(0, 16)}${walletAddress.slice(-4)}` : "Wallet address not present "}
                </Typography>
                <Button onClick={copyToClipboard}>
                  <img src={copytoClipboard} alt="Copy to clipboard" />
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <Box sx={{
              width: "164px",
              height: "105px",
              borderRadius: "24px",
              background: 'transparent',
              marginTop: "36px",
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}>
              <Box sx={{ padding: "24px" }}>
                <Typography sx={{
                  fontFamily: "Urbanist",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "17.92px",
                  color: "#010101"
                }}>
                  Entry Fee
                </Typography>
                <Typography sx={{
                  fontFamily: "Urbanist",
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "26.88px",
                  color: "#010101",
                  marginTop: "12px"
                }}>
                  1
                </Typography>
              </Box>
            </Box>
            <Box sx={{
              width: "164.5px",
              height: "105px",
              borderRadius: "24px",
              background: 'transparent',
              marginTop: "36px",
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}>
              <Box sx={{ padding: "20px", paddingTop: "20px" }}>
                <Typography sx={{
                  fontFamily: "Urbanist",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "17.92px",
                  color: "#010101"
                }}>
                  Crypto Currency
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '8px', gap: "12px", marginTop: "12px" }}>
  <img src={USDTIcon} alt="USDT Icon" style={{ display: 'block' }} />
  <Typography sx={{
    fontFamily: "Urbanist",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "26.88px",
    color: "#010101"
  }}>
    USDT
  </Typography>
</Box>

              </Box>
            </Box>
          </Box>

          <Box>
            <Button
              sx={{
                background: "#010101 !important",
                borderRadius: "36px",
                width: "183px",
                height: "72px",
                color: "#fff !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "24px 48px 24px 48px",
                position: "fixed",
                bottom: "10%",
                textTransform: "capitalize",
                fontSize:"24px"
              }}
              onClick={handleProceed}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Snackbar to show after transaction */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '90%', position: "fixed", bottom: "3%" }}>
          Transaction successful!
        </Alert>
      </Snackbar>

      {/* Modal to show after Snackbar */}



      <Snackbar
        open={showModal}
        autoHideDuration={3000} 
        onClose={handleModalClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success"  sx={{ width: '90%', position: "fixed", bottom: "3%" }}>
        <Typography variant="h6" color='black'>Enter the Game</Typography>
  
  <Typography variant="body1" color='black'>
    We’ll redirect you to take a selfie
  </Typography>
        </Alert>
      </Snackbar>
      {/* <Modal
        open={showModal}
        onClose={handleModalClose} 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
      <Box sx={{ background: 'rgb(253,224,106)', padding: '24px', borderRadius: '8px', textAlign: 'center' }}>
 
</Box>

      </Modal> */}
    </Box>
  );
};

export default Checkout;
