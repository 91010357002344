import { beginCell, Address } from "@ton/core";

const usdtContractAddress = "kQA0WKadWlKgvtn0gi02EstpNgGCR0tBiAaUzWvXtz52-Mt4";

// Function to convert TON amount to nanoTON (smallest unit)
const toNano = (amount) => {
  const tonAmount = parseFloat(amount);
  if (isNaN(tonAmount) || tonAmount <= 0) {
    throw new Error("Invalid amount provided");
  }
  // Return BigInt for storing in TON transaction
  return Number(Math.floor(tonAmount * 1e9));
};

// Define the Jetton transfer payload
const buildJettonTransferPayload = (receiverAddress, amount) => {
  // Ensure the amount is a valid number
  console.log(receiverAddress);
  console.log(typeof amount);
  
  const depositAmount = toNano(amount); // Convert the amount to nanoTON
  
  // Check if receiverAddress is valid
  let parsedReceiverAddress;
  try {
    parsedReceiverAddress = Address.parse(receiverAddress);
  } catch (error) {
    throw new Error("Invalid receiver address");
  }
  
  // Construct the Jetton transfer payload
  const body = beginCell()
    .storeUint(0xf8a7ea5, 32) // Operation ID for Jetton transfer (USDT)
    .storeUint(0, 64) // Query ID
    .storeCoins(depositAmount) // Deposit amount (in nanoTON)
    .storeAddress(parsedReceiverAddress) // Receiver address
    .storeMaybeRef(null) // No custom payload
    .storeCoins(toNano(0.03)) // Forward TON amount (0.03 TON for fees)
    .storeMaybeRef(beginCell().storeStringTail('').endCell()) // Forward payload
    .endCell();

  return body;
};

const myTransaction = (receiverAddress, amount) => ({
  validUntil: Math.floor(Date.now() / 1000) + 360, // Transaction expiry
  messages: [
    {
      address: usdtContractAddress, // USDT Jetton contract address
      amount: toNano(0.05), // Commission fee (0.03 TON)
      payload: buildJettonTransferPayload(receiverAddress, amount).toBoc().toString("base64"), // Base64 encoded Jetton transfer payload
    },
  ],
});




const Tx = {
  validUntil: Math.floor(Date.now() / 1000) + 600,
  messages: [
    {
      address: '0QDP1lQB8isB7oqm24yBsN1lf3Mz7AOGInnWR0KNYCKKGQ5G',
      amount: '1000000',
      stateInit: 'te6cckEBBAEAOgACATQCAQAAART/APSkE/S88sgLAwBI0wHQ0wMBcbCRW+D6QDBwgBDIywVYzxYh+gLLagHPFsmAQPsAlxCarA==',
      payload: 'te6ccsEBAQEADAAMABQAAAAASGVsbG8hCaTc/g==',
    },
  ],
};

export default Tx; 

// export default myTransaction;
