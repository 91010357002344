import React, { useEffect, useState } from 'react';
import { Button, Box, CardMedia, Typography} from '@mui/material';
import Onboard1 from '../../Assets/GetStartedOnbaord1.svg';
import ArrowRight from '../../Assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';


const Onboarding = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(false);
    }, []);



    const handleGetStarted = () => {
        navigate('/connectwallet'); 
    };


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="h4" color="#FFFFFF">
                    Loading...
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: '', overflow: 'hidden', position: 'relative' }}>
            <Box sx={{ width: '100%',  }} />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ position: 'relative', }}
       
            >
                {/* Image and button container */}
                <Box sx={{ position: 'relative', width: '100%' }}>
                    <Box sx={{marginTop:"36px", display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                        <Typography sx={{
                            fontFamily: "Urbanist"
                            , fontSize: "64px",
                            fontWeight: 800,
                            lineHeight: "64px",
                            letterSpacing: "3px",
                            textAlign: "center"
                        }}>
                            FACE
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Urbanist"
                            , fontSize: "24px",
                            fontWeight: 600,
                            lineHeight: "24px",
                            letterSpacing: "3px",
                            textAlign: "center",
                            transform: "rotate(-6.5deg)",
                            background: "white",
                            borderRadius: "34px",
                            marginTop: "-28px",
                            // width: "76px",
                            // height: "36px",
                            padding: "6px 8px 6px 8px"
                        }}>
                            PAL
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "10px" }}>
                        <Typography sx={{
                            fontFamily: "Urbanist",
                            fontSize: "32px",
                            fontWeight: 600,
                            lineHeight: "38.4px",
                            textAlign: "center",
                        }}>
                            Post a selfie and
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Urbanist",
                            fontSize: "32px",
                            fontWeight: 600,
                            lineHeight: "38.4px",
                            textAlign: "center",
                        }}>
                            make money!
                        </Typography>
                    </Box>

                    <CardMedia
                    src={Onboard1}
                        component="img"
                        sx={{
                            width: '100%',
                            position: "fixed",
                            bottom: "0",
                   
                            objectFit: 'cover'
                        }}
                    />

                    
                    <Button
                        sx={{
                            background: '#010101 !important',
                            borderRadius: '36px',
                            width: '264px',
                            height: '72px',
                            color: '#fff !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            position: 'fixed',
                            bottom: '25px', // Adjust to position on the image
                            left: '50%',
                            transform: 'translateX(-50%)' // Center the button horizontally
                        }}
                        
                        onClick={handleGetStarted}
                    >
                        Get Started
                        <img src={ArrowRight} width={24} height={24} alt="Arrow Right" />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Onboarding;
