import { Box, Button, Typography, Snackbar, Alert, CircularProgress } from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import Walleticon from "../Assets/WalletIcon.svg";
import EntryFees from "../Assets/EntryFees.svg";
import rewardfees from "../Assets/rewardIcon.svg";
import { TonConnectButton, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";
import defaultTx from '.././config/tx'
import CameraIcon from '../Assets/snapcamera.svg'
import TimerIcon from '../Assets/timer.svg'
import TopUpIcon from '../Assets/topupmoreselefie.svg'


const ConnectWallet = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [walletAddress, setWalletAddress] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // New state for modal status
  const navigate = useNavigate();
  // const [tx, setTx] = useState(defaultTx);
  const wallet = useTonWallet();


  const handleCheckout = () => {
    navigate("/checkout", { state: { walletAddress } });
  };

  const images = [
    Walleticon,  // Image for the first text
    CameraIcon,   // Image for the second text
    TimerIcon,    // Image for the third text
    TopUpIcon     // Image for the fourth text
  ];

  // const onChange = useCallback((value) => {
  //   setTx(value.updated_src);
  // }, []);

  useEffect(() => {
try {
      if (wallet) {
        const address = wallet.account.address || (wallet.items && wallet.items[0]?.address);
        if (address) {
          setWalletAddress(address);
          setShowSnackbar(true); // Show popup on successful connection
        } else {
          console.error("Wallet address not found.");
        }
      } else {
        console.error("Wallet is not defined.");
      }
} catch (error) {
  console.log(error);
  
}
  }, [wallet]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    if (showSnackbar) {
      const timer = setTimeout(() => {
        handleCheckout();
      }, 2000); // Delay of 2 seconds before redirecting
      return () => clearTimeout(timer);
    }
  }, [showSnackbar, walletAddress]);

  const handleConnectWallet = async () => {
    setLoading(true);
    setModalOpen(true); // Set modal open when attempting to connect
    await tonConnectUI.openModal();
    setLoading(false);
    setModalOpen(false); // Reset modal open when the modal is closed
  };

  return (
    <>
      <Box
        sx={{
          height: "96vh",
          overflow: "hidden",
          overflowY: "auto",
          background: "transparent",
          marginRight: "auto", // Set equal margin from left and right
          marginLeft: "auto",  // Set equal margin from left and right
          paddingBottom: "30px",
          maxWidth: "350px", // Max width of the container to avoid stretching too wide
          width: "100%",     // Full width for smaller screens
          px: 2,             // Add padding on both sides (left & right) for smaller screens
        }}
      >
        <Box
          m={1}
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: '16px',
            marginTop: '36px',
          }}
        >
          <Typography
            sx={{
              fontFamily: "Urbanist",
              fontSize: "32px",
              fontWeight: 900,
              lineHeight: "38.4px",
              letterSpacing: "3px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            INSTRUCTIONS
          </Typography>
          <Typography
            sx={{
              maxWidth:"107px",
              fontFamily: "Urbanist",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "3px",
              textAlign: "center",
              transform: "rotate(3.5deg)",
              background: "white",
              borderRadius: "34px",
              marginTop: "-65px",
              padding: "6px 8px",
            }}
          >
            GAME
          </Typography>
        </Box>

        {/* Instructions Box */}
        <Box
          sx={{
            
            height: "482px",
            background: "transparent",
            borderRadius: "32px",
            marginTop: "64px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            width: "100%",  // Full width
          }}
        >
          {[
            "Connect your wallet and pay 1 USDT to join and post up to 10 selfies",
            "Start snapping real-time selfies using Telegram’s camera.",
            "Keep an eye on the timer displayed at the top, if your selfie is among last 20% of photos before it elapses - you win!",
            "Top up 1 USDT to post 5 more selfies",
          ].map((text, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "24px",
                gap: "19px",
              }}
            >
                 <img src={images[index]} alt={`Icon for step ${index + 1}`} />
              <Typography sx={{ width: "262px" }}>{text}</Typography>
            </Box>
          ))}
        </Box>

        {/* Entry Fees Box */}
        <Box
          sx={{
            height: "116px",
            background: "transparent",
            borderRadius: "32px",
            marginTop: "32px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            width: "100%",  // Full width
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "19px",
            }}
          >
            <img src={EntryFees} width={206} alt="Entry Fees" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Typography
                sx={{
                  fontFamily: "Urbanist",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "16px",
                }}
              >
                Entry Fee
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Urbanist",
                  fontWeight: 800,
                  fontSize: "24px",
                  lineHeight: "16px",
                }}
              >
                1 USDT
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Reward Box */}
        <Box
          sx={{
            height: "200px",
            background: "transparent",
            borderRadius: "32px",
            marginTop: "32px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            width: "100%",  // Full width
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "24px" }}
            >
              <Typography
                sx={{
                  fontFamily: "Urbanist",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Reward
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Urbanist",
                  fontWeight: 600,
                  fontSize: "24px",
                  width: "161px",
                  lineHeight: "24px",
                }}
              >
                last 20% of players in each round get all collected fees
              </Typography>
            </Box>
            <img src={rewardfees} width={202} height={165} alt="rewardFees" />
          </Box>
        </Box>

        <Box sx={{ marginTop: "32px" }}>
          <Button
            sx={{
              background: "#010101 !important",
              borderRadius: "36px",
              width: "311px",
              height: "72px",
              color: "#fff !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              left: "50%",
              textTransform: "capitalize",
              transform: "translateX(-50%)", // Centering the button
              "&:hover": { background: "#303030" }, // Change hover color
            }}
            onClick={handleConnectWallet}
          >
            {loading && <CircularProgress size={24} color="inherit" />}
            Connect your Wallet
          </Button>
        </Box>
      </Box>

      {/* Snackbar notification */}
      <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Wallet Connected Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConnectWallet;
