import { Box, CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Onboarding from './Component/OnboardScreen/Onboard'
import ConnectWallet from "./pages/ConnectWallet";
import Checkout from './pages/Checkout/checkout';

import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";



const theme = createTheme();
const telApp = window.Telegram.WebApp;




function App() {


  const [userData, setUserData] = useState(null);

useEffect(() => {
  const fetchData = async () => {
    const search = window.Telegram.WebApp.initData;
    let data = null;

    if (search) {
      const converted = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      data = JSON.parse(converted.user);
    }

    if (data) {
      console.log('User ID:', data.id);
      console.log('User Initial Data:', data);
      setUserData(data); // Update state with the user data
    }
  };

  fetchData(); // Call the function to fetch data when the component mounts
}, []); // Empty dependency array ensures it runs only once




  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      // Set header color to bg_color from theme
      window.Telegram.WebApp.setHeaderColor('#000');
    }
  }, []);

  useEffect(() => {
    if (telApp) {
      telApp.expand();
      // telApp.enableClosingConfirmation();

      // Prevent the WebApp from closing on scroll
      telApp.onEvent('viewportChanged', () => {
        telApp.expand();
      });
    }
  }, []);
  return (
    <TonConnectUIProvider
      manifestUrl="https://sofu.blocsys.com/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/DemoDappWithTonConnectBot/demo'
      }}>


      <WebAppProvider>
        <>

        {/* {userData ? (
        <div>
          <p>User ID: {userData.id}</p>
        </div>
      ) : (
        <p>Loading user data...</p>
      )} */}
          <ThemeProvider theme={theme}>
            <BrowserRouter>

              <Routes>



                <Route path="/" element={<Onboarding
                // onFinish={handleOnboardingFinish} 
                />} />

                <Route path="/connectwallet" element={<ConnectWallet />} />
                <Route path="/checkout" element={< Checkout userData = {userData ? userData.id : ""} />} />

             
              </Routes>
              {/* {userData?.isVisited && <BottomNavbar />} */}
            </BrowserRouter>
          </ThemeProvider>
          {/* )} */}
        </>
        {/* )}
    </> */}
      </WebAppProvider>
    </TonConnectUIProvider>
  );
}

export default App;
